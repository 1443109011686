import React from 'react'
import styled from 'styled-components'
import ArticleCard from './ArticleCard'

/*
 * The <ArticlesList> component returns a <ul> element and should be used around <li> element(s)
 * The <li> element(s) are received as `props.children`
 */

/**
 * @example
 * <ArticlesList>
 *   {cardItems.map(item => (
 *     <Card key={item.id} {...item} /> // <li> element(s)
 *   ))}
 * </ArticlesList>
 */

const titleClass = 'headline--small' // Should match titleClass in <ArticleCard />

const List = styled.ul`
  display: grid;
  grid-gap: 20px;
  grid-template-columns: repeat(1, 1fr);

  &.homepage-layout {
    @media screen and (max-width: ${props =>
        props.theme.responsive.underMedium}) {
      li:nth-child(n + 4) {
        display: none;
      }
    }
  }

  @media screen and (min-width: ${props => props.theme.responsive.medium}) {
    grid-template-columns: repeat(2, 1fr);
  }

  @media screen and (max-width: ${props => props.theme.responsive.underLarge}) {
    &.homepage-layout {
      li:nth-child(n + 5) {
        display: none;
      }
    }

    > li {
      &:nth-child(1) {
        .article-excerpt {
          display: none;
        }
      }
    }
  }

  @media screen and (min-width: ${props => props.theme.responsive.large}) {
    grid-gap: 40px 30px;
    grid-template-columns: repeat(6, 1fr);

    > li {
      grid-column: span 2;

      &:nth-child(1),
      &:nth-child(2),
      &:nth-child(3) {
        max-width: none;
      }

      &:nth-child(1) {
        grid-column: 1 / -1;

        > a {
          flex-direction: row;
        }

        .is-video-thumbnail,
        .gatsby-image-wrapper {
          width: 61.5%;
          max-width: 700px;
          height: 37.75vw;
          max-height: 445px;
          margin-bottom: 0;

          + div {
            padding: 30px;

            .${titleClass} {
              margin-top: 15px;
              margin-bottom: 30px;
              font-size: ${props =>
                props.theme.typography.headlines.medium.fsLg};
              -webkit-line-clamp: 4;
              line-height: ${props =>
                props.theme.typography.headlines.medium.lhLg};
              max-height: ${props =>
                `calc(${props.theme.typography.headlines.medium.lhLg} * 4)`};
            }

            .article-excerpt {
              margin-bottom: 15px;
            }
          }
        }

        .is-video-thumbnail {
          .gatsby-image-wrapper {
            width: 100%;
          }
        }
      }

      &:nth-child(2),
      &:nth-child(3) {
        grid-column: span 3;

        .gatsby-image-wrapper {
          height: 33.1vw;
          max-height: 390px;

          + div {
            .${titleClass} {
              margin-top: 10px;
              margin-bottom: 25px;
              max-height: calc(32px * 3);
            }
          }
        }
      }
    }
  }
`

const ArticleCards = ({ articles, ...props }) => (
  <List {...props}>
    {props.articlesByCategory
      ? articles.map((article, i) => (
          <ArticleCard key={article.id} i={i} {...article} />
        ))
      : articles.map(({ node: article }, i) => (
          <ArticleCard key={article.id} i={i} {...article} />
        ))}
  </List>
)

export default ArticleCards
