import dayjs from 'dayjs'
import relativeTime from 'dayjs/plugin/relativeTime'
import { Link } from 'gatsby'
import Img from 'gatsby-image'
import React from 'react'
import styled from 'styled-components'
import PlayOverlayIcon from '../images/video-play-overlay.svg'
import theme from '../styles/theme'

dayjs.extend(relativeTime)

/*
 * The <ArticleCard> component returns a <li> element and should be used inside an <ol> or <ul>
 * The component should receive an object: {...object} which must contain:
 * * slug
 * * heroImage
 * * title
 * * publishDate
 * * category
 */

/**
 * @example
 * <ArticlesList> // a <ul> element
 *   {articles.map(({ node: article }) => (
 *     <ArticleCard key={article.id} {...article} />
 *   ))}
 * </ArticlesList>
 */

const titleClass = 'headline--small'
const titleLHSm = theme.typography.headlines.small.lhSm
const titleLHLg = theme.typography.headlines.small.lhLg

const Article = styled.li`
  width: 100%;
  max-width: 490px;
  margin-left: auto;
  margin-right: auto;

  > a {
    display: flex;
    align-items: stretch;
    justify-content: flex-start;
    flex-direction: column;
    height: 100%;
    background-color: white;
    text-decoration: none;
    color: inherit;
    -webkit-tap-highlight-color: ${theme.colors.webkitTapHighlight};
  }

  .is-video-thumbnail {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    color: white;

    > svg {
      position: absolute;
      width: 17%;
      height: 17%;
      min-width: 50px;
      min-height: 50px;
      max-width: 85px;
      max-height: 85px;
    }
  }

  .gatsby-image-wrapper {
    flex-shrink: 0;
    height: 67.75vw;
    /* max-height: 298px; */
    width: 100%;
  }

  .${titleClass} {
    margin-top: 5px;
  }

  @media screen and (min-width: ${(props) => props.theme.responsive.medium}) {
    padding-left: 0;
    padding-right: 0;

    .gatsby-image-wrapper {
      height: 29vw;
    }
  }

  @media screen and (min-width: ${(props) => props.theme.responsive.large}) {
    .gatsby-image-wrapper {
      height: 20.25vw;
      max-height: 240px;
    }
  }
`

const DetailsWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  flex: 1;
  padding: 20px 20px 25px;
`

const Title = styled.h2`
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 3;
  max-height: calc(${titleLHSm} * 3);

  @media screen and (min-width: ${(props) => props.theme.responsive.large}) {
    margin-top: 3px;
    max-height: calc(${titleLHLg} * 3);
  }
`

const ArticleCard = ({
  slug,
  videoHeader,
  heroImage,
  title,
  intro,
  publishDate,
  category,
  i,
}) => {
  const relativePublishTime = dayjs(publishDate).fromNow()

  return (
    <Article>
      <Link to={`/news/${slug}/`}>
        {videoHeader ? (
          <div className="is-video-thumbnail">
            <Img
              alt={`${title} Article Feature Image`}
              fluid={heroImage.fluid}
            />
            <PlayOverlayIcon />
          </div>
        ) : (
          heroImage?.fluid && (
            <Img
              alt={`${title} Article Feature Image`}
              fluid={heroImage.fluid}
            />
          )
        )}
        <DetailsWrapper>
          {category?.title && <p className="label">{category.title}</p>}
          <Title className={titleClass} title={title}>
            {title}
          </Title>
          {i === 0 && intro && <p className="article-excerpt">{intro}</p>}
          <p className="detail">
            {relativePublishTime.match(/second|minute/g)
              ? 'Just now'
              : relativePublishTime}
          </p>
        </DetailsWrapper>
      </Link>
    </Article>
  )
}

export default ArticleCard
